import React from "react"
import Layout from "../components/layout"
import { Container, Row, Col, Navbar, NavbarBrand } from 'reactstrap'

class AboutUsPage extends React.Component {


    render() {

        return (
            <Layout>
                <div className="wrapper">
                    <Container>
                        <Row>
                            <Col>

                                <Navbar color="dark" dark>
                                    <NavbarBrand className="mr-auto" style={{ color: "white" }}>About Us</NavbarBrand>
                                </Navbar>


                                <div style={{ backgroundColor: "white", padding: "10px 20px", marginBottom: "25px" }}>
                                    <div style={{ lineHeight: "2em" }}>This site was created because it's way too difficult to find a travel softball team today.  Depending on the area of the country you're in, you
                                        might have to search Facebook, some message boards, some local site or various other means.  Wouldn't it be nice to give teams and players a place to meet up and
                                        connect?
                                    <br />
                                        <br />
                                        We also wanted to offer tools that make it easier for parents in general, including articles related to softball, bat comparisons and more.  It's all about making it
                                        easier for parent, coaches and especially players.
                                    <br />
                                        <br />
                                        We are softball parents just like you.  We want what's best for our daughter and are trying to make it easier for everyone to find the team for them and find the information they need
                                        to be the best softball parents they can.
                                </div>
                                </div>


                            </Col>
                        </Row>

                        <Row>
                            <Col>

                                <Navbar color="dark" dark>
                                    <NavbarBrand className="mr-auto" style={{ color: "white" }}>Softball Map</NavbarBrand>
                                </Navbar>


                                <div style={{ backgroundColor: "white", padding: "10px 20px", marginBottom: "25px" }}>                                    
                                    <div style={{ lineHeight: "2em" }}>One of the key elements that we wanted to offer was a softball map that will let you easily visualize teams and facilities close to you.  A coach or team manager can update
                                        their information and keep the community aware of new tryouts or other cahnges to the team in one easy place.
                                    <br />
                                        <br />
                                        Players and parent can subscribe to our newsletter and keep track on those changes or anything else that might be of interest in your area.  It really is all about making it easier for everyone.
                                </div>
                                </div>

                            </Col>
                        </Row>

                        <Row>
                            <Col>

                                <Navbar color="dark" dark>
                                    <NavbarBrand className="mr-auto" style={{ color: "white" }}>Contact Us</NavbarBrand>
                                </Navbar>


                                <div style={{ backgroundColor: "white", padding: "10px 20px", marginBottom: "25px" }}>
                                    
                                    <div style={{ lineHeight: "2em" }}>Have a question, suggestion or anything else?  Interested in submitting an article?  Just want to talk softball?
                                <br />
                                        <br />
                                        Reach out to us at <a href="mailto:evan@fastpitchhub.com">evan@fastpitchhub.com</a> and please follow us on <a href="http://fb.me/fastpitchhub.com" target="_blank" rel="nofollow noopener noreferrer">Facebook</a>
                                    </div>
                                </div>

                            </Col>
                        </Row>



                    </Container>
                </div>
            </Layout>
        )
    }


}

export default AboutUsPage

